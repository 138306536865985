// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-contact-js": () => import("./../../../src/components/contact.js" /* webpackChunkName: "component---src-components-contact-js" */),
  "component---src-components-landing-js": () => import("./../../../src/components/landing.js" /* webpackChunkName: "component---src-components-landing-js" */),
  "component---src-components-market-range-js": () => import("./../../../src/components/market-range.js" /* webpackChunkName: "component---src-components-market-range-js" */),
  "component---src-components-products-js": () => import("./../../../src/components/products.js" /* webpackChunkName: "component---src-components-products-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */)
}

